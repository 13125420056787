import React, { ReactElement, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import AddIcon from "@mui/icons-material/Add";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { priorityLevel } from "constants/mails";
import { emailValidation } from "constants/regexp";

import AppModal from "components/shared/modal/AppModal";

import { postAddBWElement } from "redux/handlers/mailsHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { ISpamFilter } from "types/api/mailApiInterface";

type wbType = {
 spamfilter: number;
 email: string;
 priority: number;
 active: boolean;
};

const AddWBElement = ({
 type,
 spamfilter
}: {
 type: string;
 spamfilter: Array<ISpamFilter>;
}): ReactElement => {
 const intl = useIntl();
 const dispatch = useAppDispatch();

 const [isLoading, setIsLoading] = useState<boolean>(false);
 const [openModal, setOpenModal] = useState<boolean>(false);

 const handleOpen = () => {
  reset();
  setOpenModal(true);
 };
 const handleClose = () => {
  setOpenModal(false);
 };

 const { control, formState, handleSubmit, reset, getValues, watch, setValue } = useForm({
  defaultValues: {
   spamfilter: 0,
   email: "",
   priority: 0,
   active: true
  }
 });

 const onSubmit: SubmitHandler<wbType> = async (data: wbType) => {
  setIsLoading(true);
  await dispatch(postAddBWElement(data.spamfilter, data.priority, data.email, data.active, type));
  setIsLoading(false);
  setOpenModal(false);
 };

 const checkDisable = (): boolean => {
  return watch("email") === "" || !emailValidation.test(watch("email"));
 };

 return (
  <>
   <Button variant="kxActionButton" endIcon={<AddIcon />} onClick={handleOpen}>
    <FormattedMessage id={type === "w" ? "mails.add.whitelist" : "mails.add.blacklist"} />
   </Button>
   <AppModal
    open={openModal}
    close={handleClose}
    loading={isLoading}
    disabled={isLoading || checkDisable()}
    title={intl.formatMessage({ id: type === "w" ? "mails.add.whitelist" : "mails.add.blacklist" })}
    handleClose={handleClose}
    handleConfirm={handleSubmit(onSubmit)}>
    <form onSubmit={handleSubmit(onSubmit)}>
     <Stack direction="column" justifyContent="right" spacing={2.5}>
      <Controller
       name="spamfilter"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <FormControl {...field}>
         <InputLabel>
          <FormattedMessage id="mails.add.user" />
         </InputLabel>
         <Select
          value={watch("spamfilter")}
          label={<FormattedMessage id="mails.add.user" />}
          onChange={(e) => setValue("spamfilter", Number(e.target.value))}>
          {spamfilter.map((element, index) => {
           return (
            <MenuItem value={element.id} key={`spam-index-${index}`}>
             {element.email}
            </MenuItem>
           );
          })}
         </Select>
        </FormControl>
       )}
      />
      <Controller
       name="email"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <TextField
         {...field}
         fullWidth={true}
         label={<FormattedMessage id="mails.add.email" />}
         error={formState.isDirty && !!formState?.errors?.email}
         InputLabelProps={{ shrink: true }}
         sx={{ my: 2 }}
        />
       )}
      />
      <Controller
       name="priority"
       control={control}
       rules={{
        required: true
       }}
       render={({ field }) => (
        <FormControl {...field}>
         <InputLabel>
          <FormattedMessage id="mails.add.priority" />
         </InputLabel>
         <Select
          value={watch("priority")}
          label={<FormattedMessage id="mails.add.priority" />}
          onChange={(e) => setValue("priority", Number(e.target.value))}>
          {priorityLevel.map((element, index) => {
           return (
            <MenuItem value={element.value} key={`spam-index-${index}`}>
             {element.text}
            </MenuItem>
           );
          })}
         </Select>
        </FormControl>
       )}
      />
      <Controller
       name="active"
       control={control}
       render={({ field }) => (
        <FormControlLabel
         control={
          <Checkbox
           {...field}
           checked={getValues("active")}
           onChange={(e, checked) => {
            setValue("active", checked);
           }}
          />
         }
         label={intl.formatMessage({ id: "mails.add.wbActive" })}
        />
       )}
      />
     </Stack>
    </form>
   </AppModal>
  </>
 );
};

export default AddWBElement;
