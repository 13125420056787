import React, { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDebounce } from "react-use";
import { AppContext } from "AppContext";

import { isNil } from "ramda";

import SearchIcon from "@mui/icons-material/Search";

import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import EmptyList from "components/shared/emptyList/EmptyList";

import { getAllWhiteBlackList } from "redux/handlers/mailsHandler";

import { useAppDispatch } from "hooks/reduxHook";

import { IMailWBlistData, ISpamFilter } from "types/api/mailApiInterface";

import AddWBElement from "./actions/AddWBElement";
import DeleteWBElement from "./actions/DeleteWBElement";
import EditWBElement from "./actions/EditWBElement";

const MailWhitelist = ({
 id,
 spamfilter
}: {
 id: number;
 spamfilter: Array<ISpamFilter>;
}): ReactElement => {
 const dispatch = useAppDispatch();
 const { socket } = useContext(AppContext);

 const searchRef = useRef<HTMLInputElement | null>(null);

 const [whitelist, setWhitelist] = useState<Array<IMailWBlistData>>([]);
 const [whiteNumber, setWhiteNumber] = useState<number>(0);
 const [searchValue, setSearchValue] = useState<string>("");
 const [currentPage, setCurrentPage] = useState<number>(0);
 const [rowPerPage, setRowPerPage] = useState<number>(10);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [reloadData, setReloadData] = useState<boolean>(true);
 const [listWasEmpty, setListWasEmpty] = useState<boolean>(false);

 useEffect(() => {
  !isNil(socket) &&
   socket.on("maildomain_wblist", (value): void => {
    if (value?.completed) {
     setReloadData(true);
    }
   });
  return () => {
   !isNil(socket) && socket.off("maildomain_wblist");
  };
 }, [socket]);

 useEffect(() => {
  (async () => {
   if (reloadData) {
    setIsLoading(true);
    const datas = await dispatch(getAllWhiteBlackList("w", id, currentPage, rowPerPage));
    if (datas.dataset.length === 0) setListWasEmpty(true);
    setWhitelist(datas.dataset);
    setWhiteNumber(datas.totalCount);
    setIsLoading(false);
    setReloadData(false);
   }
  })();
 }, [reloadData]);

 useDebounce(
  async () => {
   if (!isLoading && searchValue.length > 0) {
    setCurrentPage(0);
    const datas = await dispatch(
     getAllWhiteBlackList("w", id, currentPage, rowPerPage, searchRef?.current?.value)
    );
    setWhitelist(datas.dataset);
    setWhiteNumber(datas.totalCount);
   }
  },
  1000,
  [searchValue]
 );

 const handleOnRowsPerPageChange = async (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
 ) => {
  const newRowsPerPage = parseInt(event.target.value, 10);
  setRowPerPage(newRowsPerPage);
  setCurrentPage(0);
  const datas = await dispatch(
   getAllWhiteBlackList("w", id, 0, newRowsPerPage, searchRef?.current?.value)
  );
  setWhitelist(datas.dataset);
  setWhiteNumber(datas.totalCount);
 };

 const handleOnPageChange = async (
  event: React.MouseEvent<HTMLButtonElement> | null,
  newPage: number
 ) => {
  const startIndex = newPage * rowPerPage;
  setCurrentPage(newPage);
  const datas = await dispatch(
   getAllWhiteBlackList("w", id, startIndex, rowPerPage, searchRef?.current?.value)
  );
  setWhitelist(datas.dataset);
  setWhiteNumber(datas.totalCount);
 };

 return isLoading ? (
  <CircularProgress />
 ) : whitelist.length > 0 || !listWasEmpty ? (
  <Stack>
   <Stack direction="row" justifyContent="space-between" p={2}>
    <Stack direction="row" alignItems="center" spacing={1}>
     <Typography fontWeight="bold" variant="caption">
      {whiteNumber} <FormattedMessage id="mails.whitelist.totalWhitelist" />
     </Typography>
     <TextField
      size="small"
      autoComplete="new-password"
      label={<FormattedMessage id="mails.whitelist.searchWhitelist" />}
      inputRef={searchRef}
      InputLabelProps={{ shrink: true }}
      InputProps={{ startAdornment: <SearchIcon color="disabled" />, autoComplete: "off" }}
      onChange={({ currentTarget }) => setSearchValue(currentTarget.value)}
     />
    </Stack>
    <Stack>
     <AddWBElement spamfilter={spamfilter} type="w" />
    </Stack>
   </Stack>
   <Stack>
    <TableContainer component="div">
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
       <TableRow>
        <TableCell>
         <FormattedMessage id="mails.whitelist.status" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="mails.whitelist.email" />
        </TableCell>
        <TableCell>
         <FormattedMessage id="mails.whitelist.priority" />
        </TableCell>
        <TableCell align="right">
         <FormattedMessage id="app.actions" />
        </TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {whitelist.map((row, index) => (
        <TableRow key={`white-index-${index}`}>
         <TableCell component="th" scope="row">
          <Chip
           size="small"
           color={row.active ? "success" : "error"}
           label={<Typography variant="caption">{row.active ? "online" : "offline"}</Typography>}
          />
         </TableCell>
         <TableCell component="th" scope="row">
          <Typography variant="subtitle2" fontWeight="bold">
           {row.email}
          </Typography>
         </TableCell>
         <TableCell component="th" scope="row">
          <Typography variant="caption">{row.priority}</Typography>
         </TableCell>
         <TableCell align="right" component="th" scope="row">
          <EditWBElement type="w" rowData={row} />
          <DeleteWBElement id={row.id} type="w" />
         </TableCell>
        </TableRow>
       ))}
      </TableBody>
     </Table>
    </TableContainer>
    <TablePagination
     component="div"
     count={whiteNumber}
     page={currentPage}
     rowsPerPage={rowPerPage}
     onPageChange={handleOnPageChange}
     onRowsPerPageChange={handleOnRowsPerPageChange}
    />
   </Stack>
  </Stack>
 ) : (
  <Stack>
   <EmptyList />
   <Stack direction="row" justifyContent="center">
    <AddWBElement spamfilter={spamfilter} type="w" />
   </Stack>
  </Stack>
 );
};

export default MailWhitelist;
